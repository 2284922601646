import { DateTime } from "luxon";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PROVIDER } from "../../constants";
import {
  actions, CreatePollSelectionsResponse, ExternalMeetingInfo, FetchReturn,
  Leader, MeetingPollPriority, MeetingStatus, RootState, ThunkDispatchType, SelectedSlots, Meeting
} from "../../store";
import { selectUserCalendarEvents, selectUserRecurringMeetingSlots } from "../../store/schedule/selectors";
import { NY_TZ, getTimeZone, getWeekRange } from "../../utils/scheduleUtils";
import PollVoting, { PollSubmission } from "./PollVotingParticipant";
import { PAGE_URL } from "../../constants";
import { createSelector } from "reselect";
import { NAVY_LOGO_SRC } from "../../resourceUrls";
import { getEmailHash } from "../../utils/authUtils";


type RouteParams = { meetingToken: string; };

const timezoneLong = DateTime.local().zoneName;
const timezoneAbbr = DateTime.local().toFormat('ZZZZ');
const timezone = getTimeZone(timezoneLong, timezoneAbbr) || NY_TZ;

const selectMeetingSlotsForCalendar = createSelector([
  (state: RootState) => state.schedule.meetingSlots,
  (state: RootState, meetingId?: Meeting['id']) => meetingId,
], (meetingSlots, meetingId) => {
  if (!meetingId) return [];

  return meetingSlots.filter(s => s.meeting !== meetingId);
});

const PollVotingParticipant = (): ReactElement => {
  const dispatch = useDispatch<ThunkDispatchType>();

  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const organization = useSelector((state: RootState) => state.organization);
  const leaders = useSelector((state: RootState) => state.leaders.leaders);
  const publicMeetings = useSelector((state: RootState) => state.schedule.publicMeetings);
  const externalParticipants = useSelector((state: RootState) => state.schedule.externalParticipants);
  const meetings = useSelector((state: RootState) => state.schedule.meetings);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<RouteParams>();

  const [calendarTimezoneSelected, setCalendarTimezoneSelected] = useState(timezone);
  const [loading, setLoading] = useState(true);
  const [returningUserEmailHashes, setReturningUserEmailHashes] = useState<{[hash: string]: string}>({});
  const [selectedLeader, setSelectedLeader] = useState<Leader | null>(null);

  const selectedLeaderCalendars = useMemo(() => selectedLeader
    ? calendars.filter(c => c.leaders.includes(selectedLeader.id)) || []
    : []
  , [calendars, selectedLeader]);

  const updateReturningUserHashes = useCallback((emails: string[]) => {
    const emailHashes = emails.map(e => ({ [getEmailHash(e)]: e }))
      .reduce((a, b) => ({ ...a, ...b }), {});
    setReturningUserEmailHashes({...returningUserEmailHashes, ...emailHashes });
  }, [returningUserEmailHashes]);

  const meetingExternalId = params.meetingToken;
  const publicPoll: ExternalMeetingInfo | undefined = meetingExternalId ? publicMeetings[meetingExternalId] : undefined;
  const externalParticipantsArray = useMemo(() => Object.values(externalParticipants), [externalParticipants]);

  const pollInfo = useMemo(() => {
    if (!publicPoll) return undefined;

    const participants: {
      [emailHash: string]: { 
        emailHash: string;
        name: string;
        email: string | null;
        selectedSlots: SelectedSlots,
        id: number,
        first_response_date: string | null
      }
    } = externalParticipantsArray.length === 0 ? {} : externalParticipantsArray.map(participant => ({
      [participant.email_hash]: {
        id: participant.id,
        emailHash: participant.email_hash,
        name: participant.name,
        email: participant.email,
        first_response_date: participant.first_response_date,
        selectedSlots: [],
      },
    })).reduce((all, p) => ({ ...all, ...p }));

    Object.values(publicPoll.poll_selections).forEach(selection => {
      const participant = publicPoll.participants[selection.participant];
      if (participant) {
        if (Object.keys(returningUserEmailHashes).includes(participant.email_hash)) return;
        if (!participants[participant.email_hash]) {
          participants[participant.email_hash] = {
            emailHash: participant.email_hash,
            name: participant.name,
            email: participant.email,
            selectedSlots: [],
            first_response_date: participant.first_response_date,
            id: participant.id,
          };
        }
        participants[participant.email_hash].selectedSlots.push({
          id: selection.id,
          start: DateTime.fromISO(selection.start_date),
          end: DateTime.fromISO(selection.end_date),
          priority: selection.priority,
        });
      }
    });

    return {
      from: publicPoll.create_user.email,
      name: publicPoll.status.id === MeetingStatus.SCHEDULED &&
       publicPoll.title_booked ? publicPoll.title_booked : publicPoll.title,
      description: publicPoll.status.id === MeetingStatus.SCHEDULED &&
       publicPoll.description_booked ?
        publicPoll.description_booked : publicPoll.description || '',
      durationMinutes: publicPoll.duration_minutes,
      expectedParticipants: publicPoll.num_expected_participants || 1,
      slots: publicPoll.booking_slots.map(slot => ({
        start: DateTime.fromISO(slot.start),
        end: DateTime.fromISO(slot.end),
        priority: MeetingPollPriority.AVAILABLE
      })),

      participants: Object.values(participants).slice().sort((a, b) => b.selectedSlots.length - a.selectedSlots.length),
    };
  }, [publicPoll, returningUserEmailHashes, externalParticipantsArray]);

  
  const logo = publicPoll ? publicPoll.create_user.active_license?.organization.logo || NAVY_LOGO_SRC : '';

  const firstSlotStart: DateTime | undefined = useMemo(() => {
    const sortedSlots = pollInfo?.slots.sort((a, b) => a.start.toMillis() - b.start.toMillis()) || [];
    return sortedSlots[0]?.start;
  }, [pollInfo?.slots]);

  const { earliestDay, endOfEarliestDay } = useMemo(() => {
    const earliest = (firstSlotStart || DateTime.now()).setZone(calendarTimezoneSelected?.name).startOf('day');
    const endOfEarliest = earliest.endOf('day');
    return { earliestDay: earliest, endOfEarliestDay: endOfEarliest };
  }, [firstSlotStart, calendarTimezoneSelected]);

  const [dateRange, setDateRange] = useState({ start: earliestDay, end: endOfEarliestDay });

  useEffect(() => {
    setDateRange({ start: earliestDay, end: endOfEarliestDay });
  }, [earliestDay, endOfEarliestDay]);

  const calendarEvents = useSelector((s: RootState) => selectUserCalendarEvents(
    s,
    new Set(calendars.map(c => c.calendar_id)),
    dateRange.start.toISO() || undefined,
    dateRange.end.toISO() || undefined,
    false,
  ));

  // filter out meeting slots that are for this current poll. They don't need to be shown in the calendar
  const meetingSlots = useSelector((s: RootState) => 
    selectMeetingSlotsForCalendar(s, publicPoll?.id)
  );

  const recurringSlots = useSelector((s: RootState) => selectUserRecurringMeetingSlots(
    s, meetingSlots, dateRange.start, dateRange.end,
  ));

  const initialSlots = useMemo(() => {
    if (Object.keys(externalParticipants).length === 0) return {};

    return Object.values(publicPoll?.poll_selections || {})
      .filter(selection => returningUserEmailHashes[externalParticipants[selection.participant]?.email_hash || ''])
      // filter to only active slots
      .filter(selection => (
        !!pollInfo?.slots.find(slot => (
          slot.start.toMillis() === DateTime.fromISO(selection.start_date).toMillis()
          && slot.end.toMillis() === DateTime.fromISO(selection.end_date).toMillis()
        ))
      ))
      .map(selection => ({
        [returningUserEmailHashes[externalParticipants[selection.participant]?.email_hash]]: [{
          id: selection.id,
          start: DateTime.fromISO(selection.start_date),
          end: DateTime.fromISO(selection.end_date),
          priority: selection.priority,
        }]
      }))
      .reduce((prev, slot) => {
        const [key] = Object.keys(slot);
        if (prev[key]) {
          return { ...prev, [key]: [...prev[key], ...slot[key]] };
        }
        return { ...prev, ...slot };
      }, {});
    // Need to do deep compares for this useEffect
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [JSON.stringify(externalParticipants), JSON.stringify(publicPoll?.poll_selections),
    JSON.stringify(returningUserEmailHashes)]);
    /* eslint-enable react-hooks/exhaustive-deps */

  // array of slots inverse to initialSlots
  const invalidSlots = useMemo(() => {
    if (Object.keys(externalParticipants).length === 0) return {};

    return Object.values(publicPoll?.poll_selections || {})
      .filter(selection => returningUserEmailHashes[externalParticipants[selection.participant]?.email_hash || ''])
      .filter(selection => !Object.values(initialSlots).flat().find(s => s.id === selection.id))
      .map(selection => ({
        [returningUserEmailHashes[externalParticipants[selection.participant]?.email_hash]]: [{
          id: selection.id,
          start: DateTime.fromISO(selection.start_date),
          end: DateTime.fromISO(selection.end_date),
        }]
      }))
      .reduce((prev, slot) => {
        const [key] = Object.keys(slot);
        if (prev[key]) {
          return { ...prev, [key]: [...prev[key], ...slot[key]] };
        }
        return { ...prev, ...slot };
      }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(returningUserEmailHashes), JSON.stringify(externalParticipants), initialSlots]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actions.schedule.fetchMeetings({ query: {status: MeetingStatus.PENDING }}));
      dispatch(actions.schedule.fetchMeetingSlots(true));
      dispatch(actions.schedule.fetchCalendars());
      dispatch(actions.leaders.fetchLeaders());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (dateRange?.start && selectedLeaderCalendars.length > 0) {
      const { start: eventsStart, end: eventsEnd } = getWeekRange(dateRange.start);

      dispatch(actions.schedule.fetchEvents({
        googleCalendarIds: selectedLeaderCalendars.filter(
          c => c.provider === PROVIDER.GOOGLE.id).map(c => c.calendar_id
        ),
        microsoftCalendarIds: selectedLeaderCalendars.filter(
          c => c.provider === PROVIDER.MICROSOFT.id).map(c => c.calendar_id
        ),
        startDate: eventsStart.toISO() || "", endDate: eventsEnd.toISO() || "",
        forTimezone: calendarTimezoneSelected?.name
      }));
    }
  }, [selectedLeaderCalendars, dateRange?.start, selectedLeader, dispatch, calendarTimezoneSelected?.name]);

  useEffect(() => {
    if (publicPoll && !publicPoll.is_poll) {
      const path = location.pathname.replace("poll", "book");
      navigate(path);
    }
  }, [location.pathname, navigate, publicPoll]);

  const handleFetchMeeting = useCallback(async () => {
    setLoading(true);
    if (params.meetingToken) {
      await dispatch(actions.schedule.fetchMeetingExternal(params.meetingToken));
    }
    setLoading(false);
  }, [dispatch, params.meetingToken]);

  useEffect(() => {
    handleFetchMeeting();
  }, [handleFetchMeeting]);

  const handleSubmit = async (submission: PollSubmission) => {
    const {attendeeName, email, selectedSlots, meetingQuestionAnswers, noTimesWork, noTimesComment} = submission;

    const selectedSlotsArray = Array.from(selectedSlots.values()).filter(slot => slot !== null) as SelectedSlots; 

    const slotsToDelete: { id: number; start: DateTime, end: DateTime }[] = invalidSlots[email]
      ? [...invalidSlots[email]]
      : [];
    let slotsToCreate: (Omit<SelectedSlots[number], "priority"> & {priority: MeetingPollPriority})[] = [];
    let slotsToUpdate: (Omit<SelectedSlots[number], "priority"> & {priority: MeetingPollPriority})[] = [];
    const promises: Promise<FetchReturn<CreatePollSelectionsResponse, string> | undefined | void>[] = [];

    if (publicPoll?.id) {
      const participant_response = await dispatch(actions.schedule.fetchOrCreateExternalParticipant({
        email: email, name: attendeeName, meeting: publicPoll?.id, first_response_date: null, required: true,
        no_times_comment: noTimesComment, calendar_access: null
      }));
      if (participant_response.status === 200 || participant_response.status === 201) {
        slotsToDelete.push(...selectedSlotsArray.filter(
          selectedSlot => selectedSlot.priority === undefined
        ));
        slotsToUpdate = selectedSlotsArray.filter(selectedSlot => (
          selectedSlot?.id !== undefined && selectedSlot.id > 0 && selectedSlot.priority != null
        )).map((slot) => ({...slot, priority: slot?.priority || MeetingPollPriority.AVAILABLE}));
        slotsToCreate = selectedSlotsArray.filter(selectedSlot => (
          selectedSlot?.id !== undefined && selectedSlot.id < 0 && selectedSlot.priority != null
        )).map((slot) => ({...slot, priority: slot?.priority || MeetingPollPriority.AVAILABLE}));

        let deletePromise = new Promise((resolve) => resolve(undefined));
        if (slotsToDelete.length > 0 && params.meetingToken) {
          deletePromise = dispatch(actions.schedule.deletePollSelections(
            params.meetingToken,
            slotsToDelete.map(slot => ({
              id: slot.id,
              email: email,
            }))
          ));
        }
        deletePromise.then(res => {
          if ((noTimesWork || selectedSlotsArray.length === 0) && params.meetingToken) {
            const newSlotsToCreate = [{ participant: participant_response.data.id }];
            const createPromise = dispatch(actions.schedule.createPollSelections(
              params.meetingToken,
              newSlotsToCreate,
            ));
            promises.push(createPromise);
          } else if (slotsToCreate.length > 0 && params.meetingToken) {
            const newSlotsToCreate = slotsToCreate.map(slot => ({
              participant: participant_response.data.id,
              start_date: slot?.start.toISO(),
              end_date: slot?.end.toISO(),
              priority: slot?.priority
            }));
            const createPromise = dispatch(actions.schedule.createPollSelections(
              params.meetingToken,
              newSlotsToCreate,
            ));
            promises.push(createPromise);
          }

          if (slotsToUpdate.length > 0 && params.meetingToken) {
            const updateSlots = slotsToUpdate.map(slot => ({
              id: slot?.id,
              participant: participant_response.data.id,
              start_date: slot?.start.toISO() || "",
              end_date: slot?.end.toISO() || "",
              priority: slot?.priority
            }));
            const updatePromise = dispatch(actions.schedule.updatePollSelections(
              params.meetingToken,
              updateSlots,
            ));
            promises.push(updatePromise);
          }
        });
        // await fetchPoll(params.meetingToken);
        // await Promise.all(promises);

        const meetingQuestionAnswerPromise = dispatch(actions.schedule.submitExternalMeetingAnswer(
          meetingQuestionAnswers.map(answer => {
            answer.participant = participant_response.data.id;
            return answer;
          })
        )).then(res => {
          if (res.status === 200 || res.status === 201) {
            updateReturningUserHashes([email]);
          }
        });

        promises.push(meetingQuestionAnswerPromise);
      }

      // await fetchPoll(params.meetingToken);
    }
    return promises;
  };

  const batchHandleSubmit = async (submissions: Array<PollSubmission>) => {
    const outerPromises: ReturnType<typeof handleSubmit>[] = [];
    submissions.forEach(submission => outerPromises.push(handleSubmit(submission)));
    const innerPromises = await (await Promise.all(outerPromises)).reduce((a, n) => a.concat(n));
    await Promise.all(innerPromises);
    if (params.meetingToken) {
      await dispatch(actions.schedule.fetchMeetingExternal(params.meetingToken));
    }
  };

  const handleDateChange = useCallback((start: DateTime, end: DateTime) => {
    setDateRange({ start, end });
  }, []);

  const handleEdit = (emails: string[]) => {
    updateReturningUserHashes(emails);
  };

  const handleUserInfoSubmit = (emails: string[]) => {
    updateReturningUserHashes(emails);
  };

  const fetchMeetingQuestionAnswers = useCallback(async (emails: string[])  => {
    const answerPromises = emails.map(email => (
      dispatch(actions.schedule.fetchExternalMeetingAnswers(publicPoll?.external_id || "", email))
    ));
    const responses = await Promise.all(answerPromises);
    return responses.map((res, i) => ({ email: emails[i], res }))
      .filter(emailRes => emailRes.res?.status === 200 && 'data' in emailRes.res)
      .map(emailRes => ({
        email: emailRes.email,
        answers: emailRes.res && 'data' in emailRes.res && 'answers' in emailRes.res.data
          ? emailRes.res.data.answers
          : []
      }));
  }, [dispatch, publicPoll]);

  const handleLogin = () => {
    navigate(PAGE_URL.LOGIN, {state: {from: { pathname: location.pathname}}});
    window.location.reload();
  };

  return (
    <PollVoting
      returningUserEmailHashes={returningUserEmailHashes}
      loading={loading}
      pollInfo={pollInfo}
      poll={publicPoll}
      onUserInfoSubmit={handleUserInfoSubmit}
      onSubmit={batchHandleSubmit}
      isAuthenticated={isAuthenticated}
      onEdit={handleEdit}
      defaultSlots={initialSlots}
      logo={logo}
      organization={organization}
      fetchMeetingQuestionAnswers={fetchMeetingQuestionAnswers}
      calendarTimezoneSelected={calendarTimezoneSelected}
      onCalendarTimezoneSelected={setCalendarTimezoneSelected}
      date={dateRange}
      onDateChange={handleDateChange}
      userData={{
        leaders,
        meetingSlots,
        recurringSlots,
        calendarEvents,
        meetings,
        calendars,
        schedulingPrefs: schedulingPrefs.user_prefs,
        selectedLeader,
        onSelectedLeaderChange: setSelectedLeader,
      }}
      onLogin={handleLogin}
    />
  );
};

export default PollVotingParticipant;
